export const columns = [
    {
		title: "预约时间",
		width: "10%",
		dataIndex: "createDate",
	},
	{
		title: "预约编号",
		width: "12%",
		dataIndex: "code",
	},
	{
		title: "预约状态",
		width: "7%",
		dataIndex: "status",
        customRender: function (type) {
            switch (type) { 
                case 1: return '未签到'
                case 2: return '已签到'
                case 3: return '已作废'
                case 4: return '已取消'
				case 5: return '已结束'
            }
        }
	},
	{
		title: "预约人",
		width: "7%",
		dataIndex: "reservationPerson",
	},
    {
		title: "预约人名称",
		width: "7%",
		dataIndex: "reservationPersonName",
	},
	{
		title: "预约开始时间",
		width: "10%",
		dataIndex: "reserveStartDate",
	},
	{
		title: "预约结束时间",
		width: "10%",
		dataIndex: "reserveEndDate",
	},
    {
		title: "用户反馈内容",
		width: "10%",
		dataIndex: "feedback",
	},
    {
		title: "作废原因",
		width: "12%",
		dataIndex: "nullifyReason",
	},
	{
		title: "操作",
		dataIndex: "action",
		key: "action",
		width: "180",
		fixed: "right",
		scopedSlots: { customRender: "action" },
	},
]

export const replyColumns = [
    {
		title: "创建人",
		width: "43%",
		dataIndex: "createUserType",
        scopedSlots: { customRender: "create" },
	},
    {
		title: "反馈内容",
		width: "43%",
		dataIndex: "content",
	},
    {
		title: "操作",
		dataIndex: "action",
		key: "action",
		width: "13%",
		scopedSlots: { customRender: "action" },
	},
]